import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
	const subtitle = data.site.siteMetadata.subtitle

  return (
    <Layout location={location} title={siteTitle} subtitle={subtitle}>
      <Seo title="About" />
      Contact Me
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
				subtitle
      }
    }
  }
`
